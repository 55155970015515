<template>
  <er-data-tables
    ref="abbreviationstable"
    :tableData="data"
    :columns="columns"
    :elTableProps="elTableProps"
    :pagination-props="$constants.table['pagination-props']"
    :actionColumn="false"
    type="white-header-table"
    :key="$i18n.locale"
    uniqueId="abbreviations_table"
  >
    <template v-slot:sno="{row}">
      {{row.data.sno}}
    </template>
    <template v-slot:meaning="{row}">
      <span class="hiding-extra-text">{{row.data.meaning}}</span>
    </template>
  </er-data-tables>
</template>

<script>
export default {
  data: function() {
    return {
      data: [{ sno: 1, key_terms: "1KG Dispense Feed", meaning: "The time taken (in seconds) by the feeder to dispense 1 Kg of feed." },
              { sno: 2, key_terms: "TF", meaning: "Total Feed: Total feed configured by the user for the existing schedule." },
              { sno: 3, key_terms: "DF", meaning: "Dispensed Feed: The amount feed dispensed by the feeder in the current feeding Schedule." },
              { sno: 4, key_terms: "Feed Cycle", meaning: "A feed cycle is an interval of time that consists of one period each of feed dispenser ON and OFF." },
              { sno: 5, key_terms: "OCF", meaning: "One Cycle Feed: The amount of feed to be dispensed in one feed cycle." },
              { sno: 6, key_terms: "FGI", meaning: "Feed Gap Interval: The time gap between start of two successive feed cycles." },
              { sno: 7, key_terms: "Mode", meaning: "The mode of operation of the feeder. The feeder can support a simple set of configuration options known as the “Basic Mode” or an advanced set known as the “Schedule Mode”." },
              { sno: 8, key_terms: "ON Time", meaning: "The amount time that the motor is ON and the feed is dispensed during a feed cycle" },
              { sno: 9, key_terms: "OFF Time", meaning: "The amount of time that the motor is OFF and the feed dispensed during a feed cycle." },
              { sno: 10, key_terms: "Schedule", meaning: "A combination of multiple feed cycles that have the same set parameters." },
              { sno: 11, key_terms: "ABW", meaning: "Average Body Weight" },
              { sno: 12, key_terms: "AWG", meaning: "Average Weekly Growth" },
              { sno: 13, key_terms: "ADG", meaning: "Average Daily Growth" },
              { sno: 14, key_terms: "FCR", meaning: "Feed Conversion Ratio (Total feed dispensed / Total biomass)" },
              { sno: 15, key_terms: "SOC", meaning: "Start of Culture" },
              { sno: 16, key_terms: "DOC", meaning: "Days of Culture" },
              { sno: 17, key_terms: "HOC", meaning: "Harvest of Culture" },
              { sno: 18, key_terms: "GW", meaning: "Gateway" },
              { sno: 19, key_terms: "PM", meaning: "PondMother" },
              { sno: 20, key_terms: "PM75", meaning: "PondMother with 75kg hopper" },
              { sno: 21, key_terms: "PM125", meaning: "PondMother with 125kg hopper" },
              { sno: 22, key_terms: "PM250-S", meaning: "PondMother with 250kg square hopper" },
              { sno: 23, key_terms: "PM250-C", meaning: "PondMother with 250kg circular hopper" },
              { sno: 24, key_terms: "PG", meaning: "PondGuard" },
              { sno: 25, key_terms: "ST", meaning: "ShrimpTalk" },
              ],
      columns: {
        sno: {
          prop: "sno",
          label: "S.No",
          minWidth: 10
        },
        key_terms: {
          prop: "key_terms",
          searchProperty: "key_terms",
          label: "Key Terms",
          minWidth: 30
        },
        meaning: {
          prop: "meaning",
          searchProperty: "meaning",
          label: "Meaning",
        }
      },
    }
  },
   mounted() {
     this.$nextTick(() => {
        if (this.$refs.abbreviationstable) {
          this.$refs.abbreviationstable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
  },
  computed: {
    elTableProps() {
      return {
        height: 'calc(100vh - 140px)'
      };
    },
  }
}
</script>

<style lang="scss">
.er-data-tables .pagination-bar .pagination-wrap {
  margin-bottom: 15px;
}
</style>
<style lang="scss" scoped>
.hiding-extra-text{
  white-space: break-spaces;
}
</style>
